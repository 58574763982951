import React, { createContext, useCallback, useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { CatalogRegistrationModal } from '..'
import { CatalogRegistrationStep, LOGIN_RESEND_TIME } from '../vars'
import { ErrorContextProvider } from '../../hooks/useErrorProvider'
import { noop } from '../../../../atoms/noop'
import { useCatalogRegistrationFormStorage } from '../../hooks/useCatalogRegistrationFormStorage'
import { usePopup } from '../../../../../youtalk-storybook/src/ui'
import { useTranslatedCatalogFiltersData } from '../../hooks/useTranslatedWizardData'

const CatalogRegistrationModalContext = createContext({})
const init = {
  step: CatalogRegistrationStep.MEET,
  canBeRequestAgain: false,
  attempt: 0
}

// eslint-disable-next-line max-lines-per-function
export const CatalogRegistrationModalContextProvider = ({
  children,
  catalogFiltersData,
  isWizard,
  psychologist
}) => {
  const { visible, hide, show } = usePopup()
  const [formData, setFormData] = useCatalogRegistrationFormStorage(init)
  const translatedCatalogFiltersData =
    useTranslatedCatalogFiltersData(catalogFiltersData)

  const updateContext = useCallback(
    (values) => {
      setFormData({ ...formData, ...values })
    },
    [formData]
  )
  const resetContext = useCallback(() => {
    setFormData(init)
  }, [])

  useEffect(() => {
    if (!visible) {
      resetContext()
    }
    return () => {
      resetContext()
    }
  }, [visible])

  useEffect(() => {
    if (
      dayjs().isAfter(
        dayjs(formData.datetime).add(LOGIN_RESEND_TIME, 'seconds')
      )
    ) {
      resetContext()
    }
  }, [])

  const stepsProps = {
    [CatalogRegistrationStep.MEET]: {
      onBack: noop
    },
    [CatalogRegistrationStep.IDENTITY]: {
      onBack: () => updateContext({ step: CatalogRegistrationStep.MEET })
    },
    [CatalogRegistrationStep.CODE]: {
      onBack: () => updateContext({ step: CatalogRegistrationStep.IDENTITY }),
      onChangePhone: () =>
        updateContext({ step: CatalogRegistrationStep.IDENTITY })
    }
  }

  return (
    <CatalogRegistrationModalContext.Provider
      value={{
        formData,
        catalogFiltersData,
        resetContext,
        updateContext,
        stepsProps,
        modal: { visible, hide, show }
      }}
    >
      <ErrorContextProvider
        allFieldsState={{
          ...formData,
          catalogFiltersData: {
            ...catalogFiltersData,
            translatedCatalogFiltersData
          }
        }}
        hide={hide}
      >
        {children}
        {visible && !isWizard && (
          <CatalogRegistrationModal psychologist={psychologist} />
        )}
      </ErrorContextProvider>
    </CatalogRegistrationModalContext.Provider>
  )
}

export const useCatalogRegistrationModalContext = () =>
  useContext(CatalogRegistrationModalContext)
