export const CatalogFieldNamesEnum = {
  WorkAreas: 'workAreas',
  Approaches: 'approaches',
  Gender: 'gender',
  Client: 'client',
  Language: 'language',
  PsychologistAge: 'psychologistAge',
  Friendliness: 'friendliness',
  IsWorkingWithTeenagerPatients: 'isWorkingWithTeenagerPatients',
  HowFeeling: 'howFeeling',
  Preference: 'preference',
  Name: 'name',
  Age: 'age',
  AboutHelp: 'aboutHelp',
  Prices: 'prices',
  Other: 'other'
}
