import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as LoaderSVG } from '/static/img/wizard/loader.svg'
import { motion } from 'framer-motion'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled(motion.span).attrs({
  children: <LoaderSVG />,
  animate: { opacity: 1 },
  transition: { delay: 0.1 }
})`
  display: block;
  height: fit-content;
  opacity: 0;
  z-index: 10;
  margin: 0 auto;
  position: relative;
  transform: translate(-50%, -50%);
  animation: ${rotate} 1s linear infinite;
`
