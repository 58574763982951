/* eslint-disable max-lines-per-function */
import { CatalogRegistrationStep } from '../vars'
import { omit } from 'rambda'
import { useCatalogRegistrationModalContext } from './useCatalogRegistrationModalContext'
import { useErrorModalContext } from '../../hooks/useErrorProvider'
import { useFormattedCatalogFiltersData } from '../../hooks/useFormattedCatalogFiltersData'
import { usePsyCardStorage } from '../../../Information/usePsyCardStorage'

export const useMeetStepSubmit = () => {
  const context = useCatalogRegistrationModalContext()
  const errorContext = useErrorModalContext()

  const [storedValue] = usePsyCardStorage()
  const formattedCatalogFiltersData =
    useFormattedCatalogFiltersData(storedValue)

  return (values) => {
    try {
      context.updateContext({
        step: CatalogRegistrationStep.IDENTITY,
        canBeRequestAgain: false,
        name: values.name,
        age: values.age,
        userRegistrationPreferences: {
          ...omit(['age', 'name', 'sourceId'], formattedCatalogFiltersData),
          aboutHelp: values.aboutHelp
        }
      })
    } catch (err) {
      console.log(err)
      errorContext.onServerError()
    }
  }
}
