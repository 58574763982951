import React from 'react'
import styled from 'styled-components'
import { Text } from '../../../youtalk-storybook/src/ui'

export const TextMediumSuperBold = styled(Text.Medium)`
  font-weight: 700;
`

const ApproachBlock = styled(({ approach, className }) => (
  <div className={className}>
    <TextMediumSuperBold>{approach.name}</TextMediumSuperBold>
    <Text.Medium semiBold>{approach.description}</Text.Medium>
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const InformationApproaches = styled(({ psychologist, className }) => (
  <div className={className}>
    {psychologist.approaches.map((approach) => (
      <ApproachBlock key={approach.id} approach={approach} />
    ))}
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
