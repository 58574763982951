import Modal from '../components/Modal'
import React, { useEffect } from 'react'
import ServerErrorPopup from '../components/ServerErrorPopup'
import ThxPopupContent from '../components/ThxPopupContent'
import { Event as GAEvent, useSubmitRequestForm } from '../components/GA'

export const FormPopup = {
  Error: (props) => {
    useEffect(() => {
      GAEvent.BypassPopup()
    }, [])
    return (
      <Modal hide={props.hide} isVisible={props.isVisible}>
        <ServerErrorPopup
          messengers
          allFieldsState={props.allFieldsState}
          hide={props.hide}
          isVisible={props.isVisible}
          telegramm="https://t.me/YouTalk_clients_bot"
        />
      </Modal>
    )
  },
  Success: (props) => {
    useSubmitRequestForm()

    return (
      <Modal {...props}>
        <ThxPopupContent
          bnsText="На главную"
          desc="Заявка сформирована и уже на пути к специалисту, он напишет вам в ближайшее время"
          title="Заявка отправлена"
          type="link"
        />
      </Modal>
    )
  }
}
