import { useAvailableSlotsContext } from '../AvailableSlotsFormProvider'
import { useEffect, useState } from 'react'

export const useExternalState = (inputValue) => {
  const { initialDate } = useAvailableSlotsContext()
  const [externalState, setExternalState] = useState(initialDate)

  useEffect(() => {
    if ((!inputValue || !externalState) && initialDate) {
      setExternalState(initialDate)
    }
  }, [initialDate])

  return [externalState, setExternalState]
}
