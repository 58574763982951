import styled from 'styled-components'
import { Checkbox } from '../../../../youtalk-storybook/src/ui'
import { Text } from '../../../atoms/Text'

export const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const CheckboxRowGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;

  ${Checkbox} {
    width: 74px;
  }
`

export const CheckboxesGroupTitle = styled(Text.Average)``
