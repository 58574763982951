import { formatDisplayNullableValue } from '../../../atoms/formatters'
import {
  useApproachesQuery,
  useWorkAresQuery
} from '../../../state/query/psychologistsFiltersData'
import { useLocation } from '@reach/router'
import { useMemo } from 'react'

const transformArrayToString = ({ title, array, map }) => {
  const transformed = (array ?? []).map((id) => map[id]).join(' | ')

  return `${title}: ${formatDisplayNullableValue(transformed, 'Не выбрано')}`
}

const useWorkAreasString = (workAreasIds = []) => {
  const { data: workAreas } = useWorkAresQuery()

  const workAreasMap = useMemo(
    () =>
      workAreas?.length > 0
        ? workAreas.reduce((acc, { _id, tag }) => {
            acc[_id] = tag
            return acc
          }, {})
        : [],
    [workAreas]
  )

  return useMemo(
    () =>
      transformArrayToString({
        title: 'Запросы',
        array: workAreas,
        map: workAreasMap
      }),
    [workAreasMap, workAreasIds]
  )
}

const useApproachesString = (approachesIds = []) => {
  const { data: approaches } = useApproachesQuery()

  const approachesMap = useMemo(
    () =>
      approaches?.length > 0
        ? approaches.reduce((acc, { _id, name }) => {
            acc[_id] = name
            return acc
          }, {})
        : [],
    [approaches]
  )
  return useMemo(
    () =>
      transformArrayToString({
        title: 'Подходы',
        array: approachesIds,
        map: approachesMap
      }),
    [approachesMap, approachesIds]
  )
}

export const useTranslatedCatalogFiltersData = (catalogFiltersData = {}) => {
  const { href } = useLocation()
  const { workAreas, approaches } = catalogFiltersData ?? {
    workAreas: [],
    approaches: []
  }
  const workAreasString = useWorkAreasString(workAreas)
  const approachesString = useApproachesString(approaches)

  const translated = useMemo(
    () => ({
      extra: [approachesString, workAreasString, `Страница психолога: ${href}`]
    }),
    [workAreasString, approachesString, href]
  )

  return translated
}
