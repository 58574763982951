import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { Text } from '../../../youtalk-storybook/src/ui'
import { ReactComponent as YellowAttentionSVG } from './img/urgent.svg'

const motionProps = {
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0,
    transition: {
      opacity: {
        delay: 0.1,
        duration: 0.33
      }
    }
  },
  initial: { opacity: 0 }
}

const Alarm = styled(({ className, visible, text, Icon, textSize }) => (
  <AnimatePresence>
    {visible && (
      <motion.div className={className} {...motionProps}>
        {Icon}
        {textSize === 'medium' ? (
          <Text.Average semiBold>{text}</Text.Average>
        ) : (
          <Text.Small>{text}</Text.Small>
        )}
      </motion.div>
    )}
  </AnimatePresence>
))`
  width: 100%;
  display: grid;
  grid-template-columns: 16px auto;
  gap: 8px;
  padding: 10px 12px 8px 12px;
  border-radius: 12px;

  ${Text.Small} {
    font-weight: 500;
  }
`

export const YellowAlarmPlate = styled(
  ({ className, visible, text, textSize }) => (
    <Alarm
      Icon={<YellowAttentionSVG />}
      className={className}
      text={text}
      textSize={textSize}
      visible={visible}
    />
  )
)`
  background: #fffaeb;
`
