import { formatCatalogData } from '../../../atoms/mappers/wizard/utils'
import { usePsychologistProfileContext } from '../../../pagesTemplates/psychologist'

export const useFormattedCatalogFiltersData = (
  catalogFiltersData,
  catalogPsychologist
) => {
  const psychologist =
    usePsychologistProfileContext()?.psychologist || catalogPsychologist

  const formatted = catalogFiltersData
    ? formatCatalogData({
        psychologistID: psychologist?.id,
        ...catalogFiltersData
      })
    : {}

  return formatted
}
