import { gql, useQuery } from '@apollo/client'

export const GET_PSYCHOLOGIST_AVAILABLE_SLOTS = gql`
  query psychologistAvailableSlots($filter: PsychologistAvailableSlotsInput!) {
    psychologistAvailableSlots(filter: $filter) {
      items {
        datetime
        isUrgent
      }
    }
  }
`
export const useGetPsychologistAvailableSlotsQuery = (input) =>
  useQuery(GET_PSYCHOLOGIST_AVAILABLE_SLOTS, {
    fetchPolicy: 'network-only',
    pollInterval: 1000 * 60 * 10, //10 min autorefetch,
    ...input
  })

export const GET_PSYCHOLOGIST_RATES = gql`
  query psychologistAvailableSlots($id: String!) {
    psychologistsCatalogItem(id: $id) {
      rates {
        startDate
        finishDate
        prices {
          language
          type
          price
        }
      }
    }
  }
`
export const useGetPsychologistRatesQuery = (input) =>
  useQuery(GET_PSYCHOLOGIST_RATES, {
    fetchPolicy: 'network-only',
    ...input
  })
