import { color } from '../../../../styles/vars/colors'
import { css } from 'styled-components'

const selectErrorCSS = ({ error }) =>
  error &&
  css`
    .rc-select-selector {
      border-color: ${color.formItem.border.error} !important;
    }

    .rc-select-selector:hover {
      background: ${color.formItem.background.errorHover};
      border-color: ${color.formItem.border.error} !important;

      .rc-select-selection-placeholder {
        background: ${color.formItem.background.errorHover};
      }
    }

    .rc-select-selector:focus-visible {
      border-color: ${color.formItem.border.error};
    }
  `

export const selectStyles = css`
  cursor: pointer;
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & input,
  input:focus {
    font-size: 16px !important;
  }

  .rc-select-arrow {
    padding: 0px 10px;
    display: flex;
    height: 100%;
    align-items: center;
    color: #9b9b9b;
  }

  .rc-select-selection-search-input {
    cursor: pointer;
  }

  .rc-select-selector {
    width: 100%;
    border: 1px solid ${color.formItem.border.default} !important;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 !important;
    height: 60px;
    background: #fff;
    position: relative;
  }

  .rc-select-selection-search,
  .rc-select-selection-placeholder {
    cursor: pointer;
  }

  .rc-select-selection-item {
    left: 0px !important;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rc-select-selection-search {
    top: 0;
    left: 0;
    position: absolute;
    max-height: 100%;
  }

  .rc-select-selection-search input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .rc-select-selection-search-input {
    padding: unset;
  }

  &:focus-within {
    .rc-select-selector {
      border: 1px solid #16c8bb !important;
    }

    ${({ error }) =>
      error &&
      css`
        .rc-select-selector {
          border-color: ${color.formItem.border.error} !important;
        }
      `}
  }

  ${selectErrorCSS}
`

export const dropDownCSS = css`
  z-index: 101;
  position: absolute;

  .rc-select-dropdown {
    padding: 4px 0;
    box-shadow: 0px 12px 80px rgba(0, 0, 0, 0.06),
      0px 0.788403px 17.869px rgba(0, 0, 0, 0.0357664),
      0px 0.117173px 5.32008px rgba(0, 0, 0, 0.0242336);
    border-radius: 4px;
    border: unset !important;
    min-height: 100% !important;
    background: ${color.background};
  }

  .rc-select-dropdown-hidden {
    display: none;
  }

  .rc-select-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 !important;
  }

  .rc-select-item-group {
    pointer-events: none;
  }

  .rc-select-item:hover {
    background: ${color.formItem.select.dropdown.hover};
  }

  .rc-select-item:active {
    background: ${color.formItem.select.dropdown.press};
  }

  .rc-select-item-option svg {
    color: #f2f2f2;
  }

  .rc-select-item-option-active {
    background: #eff5fb;
  }

  .rc-select-item-option-active svg {
    color: #e9f2fd;
  }

  .rc-select-item-option-state-icon {
    display: none;
  }

  .rc-virtual-list-holder {
    max-height: 100% !important;
  }

  .rc-virtual-list-holder::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }

  .rc-virtual-list-holder::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 3px;
    height: 131px;
  }

  .rc-virtual-list-scrollbar-thumb {
    width: 4px !important;
    background: #9d9d9d;
  }
`

export const mobileDropdownCSS = css`
  z-index: 102;

  .rc-select-dropdown {
    height: 100vh !important;
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    inset: 0 !important;
  }

  .rc-virtual-list-holder {
    max-height: 100% !important;
    overflow: show;
  }
  .rc-select-item-option svg {
    color: #f2f2f2;
  }

  .rc-select-item-option-active {
    background: #eff5fb;
  }

  .rc-select-item-option-active svg {
    color: #e9f2fd;
  }

  .rc-select-item-option-state-icon {
    display: none;
  }
`

export const optionGroupCSS = css`
  height: 40px;
  padding: 10px 14px;
`

const gradientTextBackgroundCSS = css`
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`

export const gradientTextCSS = ({ disabled }) =>
  disabled
    ? css`
        background-image: linear-gradient(
          100deg,
          rgba(194, 194, 194, 1) 0%,
          rgba(194, 194, 194, 1) 79%,
          rgba(255, 255, 255, 1) 96%,
          rgba(255, 255, 255, 1) 100%
        );
        ${gradientTextBackgroundCSS}
      `
    : css`
        background-image: linear-gradient(
          100deg,
          rgb(31, 31, 31) 0%,
          rgb(31, 31, 31) 79%,
          rgba(255, 255, 255, 1) 96%,
          rgba(255, 255, 255, 1) 100%
        );
        ${gradientTextBackgroundCSS}
      `
