import React, { useState } from 'react'
import styled from 'styled-components'
import { BottomBlock, selectorValueToProps } from '../../WebView/SelectTimeForm'
import { FormBody, ModalButtonGroup } from './index.styles'
import { InputCalendarField } from '../../SelectTimeFormFields/InputCalendarField'
import { Loader } from '../../../../../../youtalk-storybook/src/ui/atoms/Loader'
import { SessionType } from '../../../../../atoms/mappers/gqlEnums/sessionType'
import { Text } from '../../../../../../youtalk-storybook/src/ui/atoms/Text'
import { TimeSlotsField } from '../../SelectTimeFormFields/TimeSlotsField'
import { useAvailableSlotsContext } from '../../AvailableSlotsFormProvider'
import { useFormState } from 'react-final-form'

const Title = styled(Text.Large)`
  margin-bottom: 24px;
`

const StyledForm = styled.form`
  height: 100%;
`

// eslint-disable-next-line max-lines-per-function
export const SelectTimeForm = ({ handleSubmit, isModal, psychologist }) => {
  const { values } = useFormState()
  const [type] = selectorValueToProps(values.sessionTypeAndLanguage)
  const isAsyncChat = type === SessionType.AsyncChat

  const { psychologistAvailableSlots, loadingSlots } =
    useAvailableSlotsContext()

  const [isUrgent, setIsUrgent] = useState(false)

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormBody loading={loadingSlots}>
        {loadingSlots ? (
          <Loader />
        ) : (
          <>
            {isModal && <Title bold>Запись на сессию</Title>}
            <InputCalendarField
              psychologistAvailableSlots={psychologistAvailableSlots}
              updateIsUrgent={setIsUrgent}
            />
            {values.date && !isAsyncChat ? (
              <TimeSlotsField
                loadingSlots={loadingSlots}
                psychologistAvailableSlots={psychologistAvailableSlots}
                updateIsUrgent={setIsUrgent}
              />
            ) : null}
          </>
        )}
      </FormBody>
      <ModalButtonGroup>
        <BottomBlock isUrgent={isUrgent} psychologist={psychologist} />
      </ModalButtonGroup>
    </StyledForm>
  )
}
