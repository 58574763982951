import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BigGiftSVG } from '../img/bigGift.svg'
import { ReactComponent as IconGiftSVG } from '../img/gift.svg'
import { TIMERS, useShowWithTimer } from '../hooks/useFakeTimer'
import { Text } from '../../../../../youtalk-storybook/src/ui'
import { size } from '../../../../constants'

const GuideTitle = styled(Text.Medium)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  margin-bottom: 8px;

  @media (max-width: ${size.sm}) {
    max-width: 75%;
  }
`

const GuideTimer = styled(Text.Medium)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #0086c9;
`

const GuideTimerBlock = styled.div`
  display: flex;
  gap: 8px;
`

const GuideBigGift = styled(BigGiftSVG)`
  position: absolute;
  right: 24px;
  bottom: 0;

  @media (max-width: ${size.xs}) {
    right: ${(props) => (props.isMovileView ? '0px' : '48px')};
  }
`

export const GuideBlock = styled(({ isMovileView, className }) => {
  const timeRemaining = useShowWithTimer(TIMERS.GUIDE_BANNER)

  if (!timeRemaining) return null

  return (
    <div className={className}>
      <GuideTitle bold>
        Получите гайд по борьбе со стрессом за первую сессию
      </GuideTitle>
      <GuideTimerBlock>
        <IconGiftSVG />
        <GuideTimer>Осталось времени: {timeRemaining}</GuideTimer>
      </GuideTimerBlock>
      <GuideBigGift isMovileView={isMovileView} />
    </div>
  )
})`
  position: relative;
  padding: 16px;
  background-color: #f0f9ff;
  border-bottom-left-radius: ${(props) =>
    props.isMovileView ? '0px' : '16px'};
  border-bottom-right-radius: ${(props) =>
    props.isMovileView ? '0px' : '16px'};
  border: 1px solid #e6e6e6;

  @media (max-width: ${size.md}) {
    min-height: 170px;
  }

  @media (max-width: ${size.sm}) {
    min-height: 100px;
  }
`
