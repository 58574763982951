/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { AuthModal } from '../index.styles'
import { CodeStep } from './CodeStep'
import { Event as GAEvent } from '../../../components/GA'
import { IdentityStep } from './IdentityStep'
import {
  RegistrationModalContextProvider,
  useRegistrationModalContext
} from './hooks/useRegistrationModalContext'
import { RegistrationStep } from './vars'
import { isBrowser } from '../../../atoms/root'
import { selectDeviceSizeIsMobile } from '../../../state/reducers/deviceSlice'
import { useCodeStepSubmit } from './hooks/useCodeStepSubmit'
import { useIdentityStepSubmit } from './hooks/useIdentityStepSubmit'
import { useSelector } from 'react-redux'

export const RegistrationModal = ({ psychologist }) => {
  const { stepsProps, modal, ...context } = useRegistrationModalContext()
  const onIdentitySubmit = useIdentityStepSubmit(psychologist)
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  const onCodeSubmit = useCodeStepSubmit()

  useEffect(() => {
    if (isBrowser) {
      GAEvent.visitRegistration('popup')
    }
  }, [])

  const modalProps = useMemo(() => {
    const currentStep = context.formData.step

    const stepWithBackButtonProps = {
      backButtonText: `${
        currentStep === RegistrationStep.IDENTITY ? 1 : 2
      } из 2 шагов`,
      BackButtonIcon: null,
      withBackButtonHeader: true
    }
    return {
      ...stepWithBackButtonProps,
      ...(context.formData.loading
        ? {
            modalHeight: 425,
            loading: true
          }
        : {}),
      onBackButtonClick: stepsProps[currentStep].onBack
    }
  }, [context.formData, isMobile])

  return (
    <AuthModal hide={modal.hide} {...modalProps}>
      {context.formData.step === RegistrationStep.IDENTITY && (
        <IdentityStep onIdentitySubmit={onIdentitySubmit} />
      )}
      {context.formData.step === RegistrationStep.CODE && (
        <CodeStep onCodeSubmit={onCodeSubmit} />
      )}
    </AuthModal>
  )
}

export const RegistrationModalWithContext = ({
  children,
  catalogFiltersData,
  disabled
}) =>
  disabled ? (
    children
  ) : (
    <RegistrationModalContextProvider catalogFiltersData={catalogFiltersData}>
      {children}
    </RegistrationModalContextProvider>
  )
