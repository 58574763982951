import React, { createContext, useContext, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useGetPsychologistAvailableSlotsQuery } from './query'
import { useInitialValue } from './hooks/useInitialValues'
import { useLoggedUsername } from '../../../components/Header/useLoggedUsername'
import { usePsychologistProfileContext } from '../../../pagesTemplates/psychologist'

const FormContext = createContext({})

// eslint-disable-next-line max-lines-per-function
export const AvailableSlotsFormProvider = ({
  children,
  psychologistUserId
}) => {
  const context = usePsychologistProfileContext()
  const [initialDate, setInitialDate] = useState()
  const [loggedUserName] = useLoggedUsername()

  const psychologistID =
    psychologistUserId ??
    context.psychologist?.user?.id ??
    context.psychologist?.userID

  const { data, loading: loadingSlots } = useGetPsychologistAvailableSlotsQuery(
    {
      variables: {
        filter: {
          id: psychologistID
        }
      },
      onCompleted: (data) => {
        const slots = data?.psychologistAvailableSlots?.items ?? []
        const [nearestSlot] = slots

        if (loggedUserName) {
          const slots = (data?.psychologistAvailableSlots?.items ?? []).map(
            ({ datetime, isUrgent }) => ({
              text: dayjs(datetime).format('HH:mm'),
              id: datetime,
              value: datetime,
              isUrgent
            })
          )

          const filteredSlots = loggedUserName
            ? slots.filter((slot) => slot.isUrgent === false)
            : slots
          setInitialDate(dayjs(filteredSlots[0].value))
        } else {
          setInitialDate(dayjs(nearestSlot.datetime))
        }
      }
    }
  )

  const psychologistAvailableSlots = useMemo(
    () => data?.psychologistAvailableSlots?.items ?? [],
    [data?.psychologistAvailableSlots?.items]
  )

  let initialValue

  if (loggedUserName) {
    const slots = psychologistAvailableSlots.map(({ datetime, isUrgent }) => ({
      text: dayjs(datetime).format('HH:mm'),
      id: datetime,
      value: datetime,
      isUrgent
    }))

    const filteredSlots = loggedUserName
      ? slots.filter((slot) => slot.isUrgent === false)
      : slots

    initialValue = psychologistAvailableSlots.find(
      (el) => el.datetime === filteredSlots[0].value
    )
  } else {
    initialValue = psychologistAvailableSlots[0]
  }

  useInitialValue(initialValue)

  return (
    <FormContext.Provider
      value={{
        psychologistAvailableSlots,
        loadingSlots: loadingSlots || !data,
        initialDate
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useAvailableSlotsContext = () => useContext(FormContext)
