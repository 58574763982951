import { track } from '@amplitude/analytics-browser'
import { useTrackEffect } from './utils'

export const trackProfileOpen = ({ source, psy_video, id_psy }) =>
  track({
    event_type: 'profile_open',
    event_properties: {
      source,
      psy_video,
      id_psy
    }
  }).promise

export const useTrackProfileOpen = ({ source, psy_video, id_psy }) =>
  useTrackEffect(trackProfileOpen, { source, psy_video, id_psy })
