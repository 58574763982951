import styled, { css } from 'styled-components'
import {
  Button,
  InputCalendar,
  InputDatePickerHeader,
  Modal,
  PopupBody as ModalBody,
  PopupContent as ModalContent,
  color,
  size
} from '../../../../../../youtalk-storybook/src/ui'
import { Description } from '../../index.styles'
import { Loader } from '../../../../../../youtalk-storybook/src/ui/atoms/Loader'

export const StyledInputCalendar = styled(InputCalendar)`
  @media (max-width: ${size.sm}) {
    ${InputDatePickerHeader} {
      margin-bottom: 8px;
    }

    ${Description} {
      margin-bottom: 24px;
    }
  }

  abbr[title] {
    text-decoration: none !important;
  }
`

export const ModalButtonGroup = styled.div`
  && {
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${Button.NewOutline}, ${Button.NewPrimary} {
      width: 100%;
    }

    @media (max-width: ${size.sm}) {
      background: ${color.background};
      box-shadow: 3px -3px 6px rgba(25, 28, 31, 0.06),
        3px -1px 2px rgba(25, 28, 31, 0.06), 3px 1px 0px rgba(25, 28, 31, 0.04);

      width: calc(100% + 40px);
      z-index: 10;
      max-width: auto;
      padding: 16px 20px;
      transform: translateX(-20px);
      position: sticky;
      bottom: 0;

      & ${Button.NewPrimary} {
        flex-grow: 1;
      }
    }

    @media (max-width: ${size.xs}) {
      padding: 16px 16px;
      width: calc(100% + 32px);
      transform: translateX(-16px);
    }
  }
`

export const FormBody = styled.div`
  min-height: 240px;

  @media (max-width: ${size.sm}) {
    height: 100vh;
  }

  ${({ loading }) =>
    loading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      ${Loader} {
        display: block;
        position: relative;
      }
    `}
`

export const StyledModal = styled(Modal)`
  &&& {
    overflow-y: auto;
    height: var(--height);

    & ${ModalContent} {
      width: 100%;
      overflow: visible;
      display: flex;
      flex-direction: column;
    }

    & form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    ${FormBody} {
      flex: 1 0 auto;
    }

    ${ModalButtonGroup} {
      margin-top: 24px;
      flex: 0 0 auto;
    }

    @media (max-width: ${size.sm}) {
      overflow-y: unset;

      & ${ModalBody} {
        overflow-y: scroll;
        height: var(--height);
      }

      & ${ModalContent} {
        height: unset;
        max-height: none;
        padding-bottom: 0;
        min-height: var(--height);
      }
    }
  }
`
