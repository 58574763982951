import React from 'react'
import styled from 'styled-components'
import { Button, Popup } from '../../../../youtalk-storybook/src/ui'
import { ReactComponent as TeenAgePopupIconSVG } from './icons/TeenAgePopupIcon.svg'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'
import { selectDeviceSizeIsMobile } from '../../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const ButtonsGroup = styled.div.attrs(({ isMobile }) => isMobile)`
  width: 100%;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '8px' : '32px')};
  button {
    width: 100%;
  }
`

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Content = styled.div.attrs(({ isMobile }) => isMobile)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? '72px' : '0')};
`

export const TeenagerPopup = styled(
  ({ handleAgree, hide, title, text, agreeText, declineText, className }) => {
    const isMobile = useSelector(selectDeviceSizeIsMobile)
    return (
      <Popup
        className={className}
        hide={hide}
        isMobile={isMobile}
        showCloseButton={!isMobile}
      >
        <Content isMobile={isMobile}>
          <TeenAgePopupIconSVG />
          <TextGroup>
            <Title.H3>{title}</Title.H3>
            <Text.Average>{text}</Text.Average>
          </TextGroup>
          <ButtonsGroup isMobile={isMobile}>
            <Button.NewPrimary onClick={handleAgree}>
              {agreeText}
            </Button.NewPrimary>
            <Button.NewOutline onClick={hide}>{declineText}</Button.NewOutline>
          </ButtonsGroup>
        </Content>
      </Popup>
    )
  }
)`
  z-index: 20;
`
