import { eventProperties } from './vars'
import { track } from '@amplitude/analytics-browser'
import { useTrackEffect } from '../utils'

const trackCodeEnter = ({ route, badge }) =>
  track({
    event_type: 'code_enter',
    event_properties: {
      ...eventProperties,
      route,
      badge
    }
  }).promise

export const useTrackCodeEnterEffect = (input) =>
  useTrackEffect(trackCodeEnter, input)
