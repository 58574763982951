/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable no-unused-vars */
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export enum TIMERS {
  GUIDE_BANNER = 'timer_guide_banner'
}

const getTargetTime = (timer: TIMERS) => {
  const targetDateString = window.localStorage.getItem(timer)
  if (!targetDateString) {
    const targetDate = dayjs().add(24, 'hours')
    window.localStorage.setItem(timer, targetDate.format())
    return targetDate
  }
  return dayjs(targetDateString)
}

export const useShowWithTimer = (timer: TIMERS) => {
  const [timeRemaining, setTimeRemaining] = useState('')

  useEffect(() => {
    const targetDate = getTargetTime(timer)
    const updateCountdown = () => {
      const now = dayjs()
      const difference = targetDate.diff(now, 'seconds')
      const differenceHours = targetDate.diff(now, 'hours')

      if (difference <= 0) {
        window.localStorage.setItem(timer, targetDate.add(1, 'week').format())
        setTimeRemaining('')
        return
      }
      if (differenceHours < 24) {
        const hours = Math.floor(difference / (60 * 60))
        const minutes = Math.floor((difference % (60 * 60)) / 60)
        const seconds = Math.floor(difference % 60)

        const formattedTime = `${String(hours).padStart(2, '0')}:${String(
          minutes
        ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

        setTimeRemaining(formattedTime)
        return
      }
      setTimeRemaining('')
    }

    updateCountdown()
    const interval = setInterval(updateCountdown, 1000)

    return () => clearInterval(interval)
  }, [timer])

  return timeRemaining
}
