import { Gender } from '../../../atoms/mappers/gqlEnums/gender'
import { HowFeeling } from '../../../atoms/mappers/gqlEnums/howFeeling'
import { Language } from '../../../atoms/mappers/gqlEnums/language'
import { RegistrationSource } from '../../../atoms/mappers/gqlEnums/registrationSourse'
import { SourceID } from '../../../atoms/mappers/wizard/enums/sourceId'
import { TherapyType } from '../../../atoms/mappers/gqlEnums/therapyType'
import { array, boolean, date, number, object, string } from 'yup'

const positiveInteger = number().positive().integer()
const registrationSource = string().oneOf(Object.values(RegistrationSource))
const language = string().oneOf(Object.values(Language))
const therapyType = string().oneOf(Object.values(TherapyType))
const clientFeelings = string().oneOf(Object.values(HowFeeling))
const gender = string().oneOf(Object.values(Gender))
const sourceID = string().oneOf(Object.values(SourceID))

const pricesFilter = object({
  from: number().integer(),
  to: positiveInteger
})

const psychologistPreference = object({
  gender: gender,
  ageMin: positiveInteger.required(),
  ageMax: positiveInteger.required(),
  lgbtq: boolean().required()
})

const userRegistrationPreferences = object({
  sessionDateTime: date().required(),
  clientFeelings: clientFeelings,
  language: language.required(),
  clientType: therapyType.required(),
  workAreas: array().of(string()).required(),
  workApproaches: array().of(string()).required(),
  psychologistPreference,
  psychologistID: string().required(),
  aboutHelp: string(),
  aboutPsychologist: string().required(),
  prices: array().of(pricesFilter)
})

const analyticsData = object({
  sourceId: sourceID.required()
})

export const sendRegistrationRequestInputSchema = object({
  phone: positiveInteger.required(),
  name: string().required(),
  age: positiveInteger.required(),
  registrationSource: registrationSource.required(),
  email: string(),
  timezone: string(),
  promocodeId: string(),
  userRegistrationPreferences: userRegistrationPreferences.required(),
  analyticsData: analyticsData.required()
})
