import Footer from '../components/Footer'
import React, { useEffect, useMemo } from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { ApplicationFormBlock } from './Information/applicationFormBlock'
import { CalendarBlock } from './Information/CalendarView'
import { Loader } from '../atoms/WizardLoader'
import { PSYCHOLOGIST_WORKS_WITH_TEENS } from '../constants'
import { PsychologistInfo } from './Information'
import { color } from '../styles/vars/colors'
import { getExperienceWithText } from '../atoms/ageParser'
import { getStrapiAvatarUrl } from '../atoms/getStrapiAvatarUrl'
import { pick } from 'ramda'
import { size, useSessionStorage } from '../../youtalk-storybook/src/ui'
import { useCheckIsWizard } from '../hooks/useCheckIsWizard'
import { useGetPsychologistActualData } from './Information/query'
import { useGetPsychologistAvailableSlotsQuery } from './Information/CalendarView/query'
import { useScrollEffect } from '../atoms/useScrollEffect'
import { useTrackProfileOpen } from '../components/amplitude/trackProfileOpen'

const getApproachText = (approaches) => {
  const [approach] = approaches ?? []

  return approach?.name
    ? `, работает в таких подходах, как ${approach.name.toLowerCase()}`
    : ''
}

const getApproachTextTitle = (approaches) => {
  const [approach] = approaches ?? []

  return approach?.name ? ` ${approach.name.toLowerCase()}` : ''
}

const getExperienceText = (startOfPractice) =>
  startOfPractice ? ` опыт более ${getExperienceWithText(startOfPractice)}` : ''

const getNameText = (name) => (name ? ` ${name}` : '')

const getSEODescription = ({
  name,
  start_of_practice: startOfPractice,
  approaches,
  surname
}) =>
  `${[
    'Психолог',
    getNameText(name, surname),
    getNameText(surname),
    getExperienceText(startOfPractice),
    getApproachText(approaches)
  ].join('')}. Ознакомиться с информацией о 
  специалисте и записаться на прием вы можете на сайте сервиса YouTalk.`

const getSEOTitle = ({
  name,
  start_of_practice: startOfPractice,
  approaches,
  surname
}) =>
  `${[
    'Психолог ',
    getNameText(name),
    getNameText(surname),
    getExperienceText(startOfPractice),
    getApproachTextTitle(approaches)
  ].join('')} - записаться на онлайн-консультацию | YouTalk`

const getSEOImage = (photo) =>
  pick(photo ? ['images'] : [], {
    images: [
      {
        pathname: getStrapiAvatarUrl(photo, 'small_'),
        alt: 'psychologist'
      }
    ]
  })

export const CalendarOrApplicationForm = ({
  isWizard,
  isActive,
  isAvailable,
  psychologist,
  psychologistUserId
}) => {
  const { data, loading } = useGetPsychologistAvailableSlotsQuery({
    variables: {
      filter: {
        id: psychologistUserId || psychologist.user?.id
      }
    }
  })

  useTrackProfileOpen({
    source: isWizard ? 'wizard' : 'catalog',
    psy_video: psychologist?.video_url ? 'yes' : 'no',
    id_psy: psychologist?.id
  })

  const hasAvailableSlots = useMemo(
    () => data?.psychologistAvailableSlots?.items.length > 0 ?? false,
    [data?.psychologistAvailableSlots?.items]
  )

  const isNeedToHideBlock = !isAvailable

  return (
    <>
      {loading || isNeedToHideBlock ? (
        <></>
      ) : isActive && hasAvailableSlots ? (
        <CalendarBlock
          isWizard={isWizard}
          psychologist={psychologist}
          psychologistUserId={psychologistUserId}
        />
      ) : (
        <ApplicationFormBlock />
      )}
    </>
  )
}

const LoaderBlock = () => (
  <div>
    <Loader />
  </div>
)

const PsychologistInfoWithCalendar = styled(({ psychologist, className }) => {
  const isWizard = useCheckIsWizard()
  const { data, loading } = useGetPsychologistActualData({
    variables: {
      id: psychologist.id
    }
  })
  const psychologistActual = data?.psychologistsCatalogItem
  const [_, setSessionPsychologist] = useSessionStorage(
    PSYCHOLOGIST_WORKS_WITH_TEENS,
    false
  )
  useEffect(() => {
    setSessionPsychologist(psychologistActual?.isWorkingWithTeenagerPatients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psychologistActual?.isWorkingWithTeenagerPatients])

  return (
    <div className={className}>
      <PsychologistInfo psychologist={psychologist} />
      {loading ? (
        <LoaderBlock />
      ) : (
        <CalendarOrApplicationForm
          isActive={psychologistActual.isActive}
          isAvailable={psychologistActual.isAvailable}
          isWizard={isWizard}
          psychologist={psychologist}
        />
      )}
    </div>
  )
})`
  display: flex;
  gap: 24px;
  justify-content: center;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`

export const PsychologistCard = styled(
  ({ psychologist, locationPsychologistId, className }) => {
    useScrollEffect(locationPsychologistId)

    return (
      <div className={className}>
        <SEO
          description={getSEODescription(psychologist)}
          title={getSEOTitle(psychologist)}
          {...getSEOImage(psychologist?.photo)}
        />
        <PsychologistInfoWithCalendar psychologist={psychologist} />
        <Footer />
      </div>
    )
  }
)`
  background: ${color.profile.background};
  padding-top: 48px;
`
