import React from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { FormItemOnControl, Input } from '../../../youtalk-storybook/src/ui'
import { notEmpty } from '../../atoms/FormItems/validators/notEmpty'

export const CodeField = () => {
  const { submitErrors } = useFormState()
  const {
    mutators: { clearSubmitError }
  } = useForm()

  return (
    <Field name="code" validate={notEmpty('')}>
      {({ input, meta }) => (
        <FormItemOnControl {...input} meta={meta} mode="submitFailed">
          <Input
            inputMode="numeric"
            maxLength="4"
            {...input}
            error={Boolean(submitErrors?.code)}
            onChange={(event) => {
              const value =
                typeof event === 'object' ? event.target.value : event
              input.onChange(value)
              submitErrors?.code && clearSubmitError('code')
            }}
            placeholder="Укажите код"
          />
        </FormItemOnControl>
      )}
    </Field>
  )
}
