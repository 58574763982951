import { gql, useQuery } from '@apollo/client'
import { isBrowser } from '../../atoms/root'
import { isEmpty } from 'rambda'

const GET_WORK_APPROACHES = gql`
  query workApproaches {
    workApproaches {
      id
      name
    }
  }
`

const GET_WORK_AREAS = gql`
  query workAreas {
    workAreas {
      id
      items {
        id
        name
      }
      name
    }
  }
`

export const useApproachesQuery = (input) => {
  const { data, ...query } = useQuery(GET_WORK_APPROACHES, {
    skip: !isBrowser || input?.skip
  })

  const approaches = (data?.workApproaches ?? []).map((approach) => ({
    ...approach,
    _id: approach.id
  }))

  return {
    ...query,
    isSuccess: !isEmpty(approaches ?? []) && !query.loading,
    data: approaches ?? []
  }
}

export const useWorkAresQuery = () => {
  const { data, ...query } = useQuery(GET_WORK_AREAS, {
    skip: !isBrowser
  })

  const workAreas = (data?.workAreas ?? []).reduce((acc, workAreaGroup) => {
    workAreaGroup.items.map(({ name, id }) => {
      acc.push({
        group: {
          name: workAreaGroup.name,
          type: workAreaGroup.id
        },
        tag: name,
        id,
        _id: id
      })
      return null
    })
    return acc
  }, [])

  return {
    ...query,
    isSuccess: !isEmpty(workAreas ?? []) && !query.loading,
    data: workAreas ?? []
  }
}
