import styled from 'styled-components'
import { ReactComponent as InfoSVG } from './img/info.svg'
import {
  Modal,
  PopupBody as ModalBody,
  PopupContent as ModalContent,
  color
} from '../../../youtalk-storybook/src/ui'
import { Text } from '../../atoms/Text'
import { size } from '../../constants'

export const AgreementText = styled(Text.Small)`
  font-weight: 500;
  width: 100%;
  color: #9b9b9b;
  a {
    text-decoration: underline;
    color: #9b9b9b;
    &:hover {
      color: #9b9b9b;
    }
  }
`

export const NoSmsText = styled(Text.Average)`
  font-weight: 500;
  width: 100%;
  color: #9b9b9b;
  button {
    padding: 0;
    font-weight: 500;
    border: none;
    outline: none;
    background: transparent;
    text-decoration: underline;
    color: #9b9b9b;
    &:hover {
      color: #9b9b9b;
    }
  }
`

export const ModalButtonGroup = styled.div`
  && {
    width: 100%;
    @media (max-width: ${size.sm}) {
      background: ${color.background};
    }
  }
`

export const Title = styled(Text.Large)`
  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const SmsInfoBlock = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  background-color: #f0f9ff;
  justify-content: center;
  gap: 8px;
`

export const SmsInfoSvg = styled(InfoSVG)``

export const SmsInfoText = styled(Text.Average)`
  font-weight: 500;
  font-size: 14px;
  width: 100%;
`

export const Paragraph = styled(Text.Average)`
  font-weight: 500;
  width: 100%;
`

export const FormHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FormWrapWithoutButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormMain = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const AuthModal = styled(Modal)`
  overflow-y: auto;
  height: var(--height);
  z-index: 13;

  ${ModalContent} {
    max-width: 425px;
    width: 100%;
    overflow: visible;
    display: flex;
    flex-direction: column;
  }

  ${FormWrapWithoutButton} {
    flex: 1 0 auto;
  }

  ${ModalButtonGroup} {
    flex: 0 0 auto;
  }

  @media (max-width: ${size.sm}) {
    overflow-y: unset;

    & ${ModalBody} {
      overflow-y: auto;
      height: var(--height);
    }

    & ${ModalContent} {
      height: unset;
      max-height: none;
      max-width: 100vw;
      padding-bottom: 0px;
      min-height: var(--height);
    }
  }
`
