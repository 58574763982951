export const TRACK_SIGNUP_METHOD = 'phone'
export const TRACK_SIGNUP_STAGE = 'signup'

export const eventProperties = {
  stage: TRACK_SIGNUP_STAGE,
  method: TRACK_SIGNUP_METHOD
}

export const RegistrationRoute = {
  Application: 'application',
  Wizard: 'wizard'
}
