import dayjs from 'dayjs'
import { Identify, identify } from '@amplitude/analytics-browser'
import { WizardTherapyType } from '../../atoms/mappers/wizard/enums/therapyType'

const FAMILY = 'family'

const therapyTypeMap = {
  [WizardTherapyType.Individual]: WizardTherapyType.Individual,
  [WizardTherapyType.FamilyPartner]: FAMILY,
  [WizardTherapyType.FamilyChild]: FAMILY
}

export const identifyObj = new Identify()

export const amplitudeIdentify = ({ therapyType }) => {
  identifyObj.set('type', therapyTypeMap[therapyType])

  return identify(identifyObj).promise
}

export const amplitudeIdentifyOnceCohortYear = () => {
  identifyObj.setOnce('cohort_year', dayjs().year())

  return identify(identifyObj).promise
}

export const amplitudeIdentifyOnceCohortWeek = () => {
  identifyObj.setOnce('cohort_week', dayjs().isoWeek())

  return identify(identifyObj).promise
}

export const amplitudeIdentityPhone = (phone) => {
  identifyObj.set('phone', phone)

  return identify(identifyObj).promise
}

export const amplitudeIdentifyLeadId = (leadId) => {
  identifyObj.set('leadID', leadId)

  return identify(identifyObj).promise
}

export const amplitudeIdentifyAbTestParticipants = (testName, value) => {
  identifyObj.set(testName, value)

  return identify(identifyObj).promise
}

export const amplitudeIdentifyRegistrationDate = () =>
  Promise.all([
    amplitudeIdentifyOnceCohortYear(),
    amplitudeIdentifyOnceCohortWeek()
  ])
