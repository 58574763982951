import React from 'react'
import styled from 'styled-components'
import { ReactComponent as EducationConfirmedSVG } from './img/educationConfirmed.svg'
import { Text } from '../../../youtalk-storybook/src/ui'
import { TextMediumSuperBold } from './approachesInfo'

const DocumentsConfirmed = styled(({ className }) => (
  <div className={className}>
    <EducationConfirmedSVG />
    <Text.Medium semiBold>Документы подтверждены</Text.Medium>
  </div>
))`
  display: flex;
  gap: 8px;
  color: #0f8a05; // add to colors
`

const TextMediumGrayColored = styled(Text.Medium)`
  color: #838383; //add to colors
  font-weight: 500;
`

const EducationCaption = styled(({ education, className }) => {
  const educationDates =
    education.yearStart === education.yearEnd
      ? education.yearStart
      : `${education.yearStart} - ${education.yearEnd}`

  return (
    <div className={className}>
      <TextMediumSuperBold>{educationDates}</TextMediumSuperBold>
      <Text.Medium semiBold>{education.title}</Text.Medium>
      <TextMediumGrayColored>{education.speciality}</TextMediumGrayColored>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const EducationDetails = styled(({ educations, label, className }) => (
  <div className={className}>
    <TextMediumSuperBold>{label}</TextMediumSuperBold>
    {educations.map((education, index) => (
      <EducationCaption key={education.type + index} education={education} />
    ))}
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InformationEducations = styled(({ psychologist, className }) => (
  <div className={className}>
    <DocumentsConfirmed />
    <EducationDetails
      educations={psychologist.educations.filter(
        (ed) => ed.type === 'Education'
      )}
      label="Высшее образование"
    />
    <EducationDetails
      educations={psychologist.educations.filter(
        (ed) => ed.type === 'Training'
      )}
      label="Повышение квалификации"
    />
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
