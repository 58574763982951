import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BadgeStarSVG } from './img/badgeStar.svg'
import { Text } from '../../../youtalk-storybook/src/ui'
import { getBadgesToShow } from '../../atoms/badgesToShow'

const IconWithText = styled(({ className, badge }) => (
  <div className={className}>
    <BadgeStarSVG />
    <Text.Average>{badge?.name}</Text.Average>
  </div>
))`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  ${Text.Average} {
    font-weight: 600;
  }
`

const PsychologistBadge = styled(({ className, badge }) => (
  <div className={className}>
    <IconWithText badge={badge} />
    <Text.Small>{badge?.description}</Text.Small>
  </div>
))`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #c1c5cd;
  width: 100%;

  ${Text.Small} {
    font-weight: 500px;
  }
`

export const BadgesBlock = styled(({ className, psychologist }) => {
  const badgesToShow = getBadgesToShow(psychologist?.badges)

  if (!badgesToShow) {
    return null
  }

  return (
    <div className={className}>
      {badgesToShow.map((badge) => (
        <PsychologistBadge key={badge.id} badge={badge} />
      ))}
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
