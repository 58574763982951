/* eslint-disable react-hooks/exhaustive-deps */
import '../../../../youtalk-storybook/src/ui/daysjsConfig'
import React, { useMemo } from 'react'
import { AvailableSlotsFormProvider } from './AvailableSlotsFormProvider'
import { CatalogRegistrationModalContextProvider } from '../../RegistrationModals/CatalogRegistrationModal/hooks/useCatalogRegistrationModalContext'
import { Form } from 'react-final-form'
import { Language } from '../../../atoms/mappers/gqlEnums/language'
import { MobileView } from './MobileView'
import { RegistrationModalContextProvider } from '../../RegistrationModals/RegistrationModal/hooks/useRegistrationModalContext'
import {
  SelectTimeForm,
  propsToValueForSelector
} from './WebView/SelectTimeForm'
import { SelectTimeModal } from './MobileView/SelectTimeModal'
import { isEmpty } from 'ramda'
import { isMobile } from '../../../../youtalk-storybook/src/ui/atoms/isMobile'
import { languageMap } from '../../../atoms/mappers/wizard/languageMap'
import { mobileDropdownCSS } from './WebView/selectorStyles'
import { trackWizardCalendarOpen } from '../../../components/amplitude/trackWizardCalendarOpen'
import { useFilterStorage } from '../useFilterStorage'
import { useFormSubmit } from './hooks/useFormSubmit'
import { useGetParentRoute } from './useGetParentRoute'
import { usePopup } from '../../../../youtalk-storybook/src/ui'
import { usePsyCardStorage } from '../usePsyCardStorage'
import { usePsychologistProfile } from '../../../components/GA'
import { useValidateStorageData } from '../useValidateStorageData'
import { wizardValuesToValidSessionTypeEnum } from '../../../atoms/mappers/wizard/utils'

const POPUP_CONTAINER_ID = 'select-in-popup'

export const useGetInitialValue = (psychologist) => {
  const [storedValue] = usePsyCardStorage()
  const [filter] = useFilterStorage()
  const info = isEmpty(storedValue) ? filter : storedValue

  const sessionInfoWizard = {
    language: languageMap[info?.language] ?? Language.Russian,
    type: wizardValuesToValidSessionTypeEnum({
      therapyType: info?.client,
      sessionFormat: info?.sessionFormat
    })
  }

  const initValueFromStore = propsToValueForSelector({
    type: sessionInfoWizard.type,
    language: sessionInfoWizard.language
  })

  if (initValueFromStore.length > 1) {
    return {
      sessionTypeAndLanguage: initValueFromStore
    }
  }

  const firstRatesOption = psychologist?.rates[0]?.prices[0]
  const initValueFromRates = firstRatesOption
    ? propsToValueForSelector({
        type: firstRatesOption.type,
        language: firstRatesOption.language
      })
    : ''

  return {
    sessionTypeAndLanguage: initValueFromRates
  }
}

export const CalendarView = ({ psychologist, psychologistUserId }) => {
  const {
    show: showCalendar,
    hide: hideCalendar,
    visible: visibleCalendar
  } = usePopup()

  const submit = useFormSubmit()

  const mobileViewProps = useMemo(
    () => ({
      onClickSelectTimeButton: () => {
        trackWizardCalendarOpen()
        showCalendar()
      },
      popupContainerId: POPUP_CONTAINER_ID,
      psychologist
    }),
    []
  )

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={useGetInitialValue(psychologist)}
      onSubmit={submit}
    >
      {({ handleSubmit, values }) => (
        <AvailableSlotsFormProvider
          psychologistUserId={psychologistUserId ?? psychologist?.user?.id}
        >
          {!isMobile && (
            <SelectTimeForm
              handleSubmit={handleSubmit}
              psychologist={psychologist}
              psychologistUserId={psychologistUserId}
              values={values}
            />
          )}
          {isMobile && <MobileView {...mobileViewProps} />}
          <div css={mobileDropdownCSS} id={`dropdown-${POPUP_CONTAINER_ID}`} />
          {visibleCalendar && (
            <SelectTimeModal handleSubmit={handleSubmit} hide={hideCalendar} />
          )}
        </AvailableSlotsFormProvider>
      )}
    </Form>
  )
}

export const CalendarBlock = ({
  psychologist,
  isWizard,
  psychologistUserId
}) => {
  const parentRoute = useGetParentRoute()
  usePsyCardStorage()
  useValidateStorageData(isWizard)
  usePsychologistProfile(parentRoute)

  return (
    <CatalogRegistrationModalContextProvider isWizard={isWizard}>
      <RegistrationModalContextProvider
        isWizard={isWizard}
        psychologist={psychologist}
      >
        <CalendarView
          psychologist={psychologist}
          psychologistUserId={psychologistUserId}
        />
      </RegistrationModalContextProvider>
    </CatalogRegistrationModalContextProvider>
  )
}
