import React, { createContext, useCallback, useContext } from 'react'
import { ErrorMessageSourceEnum } from '../../../formData/createMessage'
import { FormPopup } from '../../FormPopup'
import { IdentityNotAvailablePopup } from '../../IdentityNotAvailablePopup'
import { MessengersPopup } from '../../../molecules/MessengersPopup'
import { trackRegistrationAccountExist } from '../../../components/amplitude/trackRegistrationAccountExist'
import { usePopup } from '../../../../youtalk-storybook/src/ui'

const ErrorModalContext = createContext({})

export const ErrorContextProvider = ({ children, hide, allFieldsState }) => {
  const {
    visible: messengersPopupVisible,
    show: showMessengersPopup,
    hide: hideMessengersPopup
  } = usePopup()
  const {
    visible: identityTakenWarningVisible,
    toggle: tglIdentityTakenWarning
  } = usePopup()
  const { visible: serverErrorPopupVisible, toggle: toggleServerErrorPopup } =
    usePopup()

  const onIdentityTakenError = useCallback(() => {
    hide()
    tglIdentityTakenWarning()
  }, [])

  const onServerError = useCallback(() => {
    hide()
    toggleServerErrorPopup()
  }, [])

  return (
    <ErrorModalContext.Provider
      value={{
        onServerError,
        onIdentityTakenError,
        showMessengersPopup
      }}
    >
      {children}
      {serverErrorPopupVisible && (
        <FormPopup.Error
          allFieldsState={{
            ...(allFieldsState ?? {}),
            queryType: ErrorMessageSourceEnum.Registration
          }}
          hide={toggleServerErrorPopup}
        />
      )}
      {identityTakenWarningVisible && (
        <IdentityNotAvailablePopup
          hide={tglIdentityTakenWarning}
          onOpen={trackRegistrationAccountExist}
        />
      )}
      {messengersPopupVisible && (
        <MessengersPopup hide={hideMessengersPopup} title="Не приходит код?" />
      )}
    </ErrorModalContext.Provider>
  )
}

export const useErrorModalContext = () => useContext(ErrorModalContext)
