/* eslint-disable max-lines-per-function */
import React, { useCallback } from 'react'
import createDecorator from 'final-form-focus'
import styled from 'styled-components'
import { ButtonAsLink } from '../../../../../youtalk-storybook/src/ui'
import { CatalogRegistrationStep, subscription } from '../vars'
import { CodeField } from '../../CodeField'
import { Form as FinalForm, useField } from 'react-final-form'
import {
  FormBody,
  FormHeader,
  FormItems,
  FormMain,
  FormWrapWithoutButton,
  ModalButtonGroup,
  Paragraph,
  Title
} from '../../index.styles'
import { FormButton } from '../../FormButton'
import { RequestCodeAgain } from '../../RequestCodeAgain'
import { clearSubmitError, submit } from '../../../../atoms/useFormValues'
import { formatPhoneNumber } from '../../../../atoms/FormItems/formatters/phoneNumber'
import { trackCodeSendAgain } from '../../../../components/amplitude/auth/trackCodeSendAgain'
import { useCatalogRegistrationModalContext } from '../hooks/useCatalogRegistrationModalContext'
import { useErrorModalContext } from '../../hooks/useErrorProvider'
import { useIdentityStepSubmit } from '../hooks/useIdentityStepSubmit'

const focusOnError = createDecorator()

const Form = styled(
  ({ className, handleSubmit, restart, errors, psychologist }) => {
    const context = useCatalogRegistrationModalContext()
    const identity = useIdentityStepSubmit(psychologist)

    const requestCode = useCallback(async () => {
      trackCodeSendAgain()
      context.updateContext({ loading: true })
      const result = await identity({
        tel: context.formData.tel,
        name: context.formData.name,
        age: Number(context.formData.age),
        attempt: Number(context.formData.attempt ?? 0)
      })
      context.updateContext({
        loading: false,
        attempt: context.formData.attempt + 1,
        token: result,
        canBeRequestAgain: false,
        datetime: new Date()
      })
    }, [context.formData])

    const { stepsProps } = context
    const errorContext = useErrorModalContext()
    const {
      input: { value: code }
    } = useField('code')

    const formattedPhone = formatPhoneNumber(
      context.formData.tel,
      context.formData.country
    )

    return (
      <FormMain
        className={className}
        onSubmit={submit({
          handleSubmit,
          restart,
          errors
        })}
      >
        <FormWrapWithoutButton>
          <FormHeader>
            <Title bold>Введите код из SMS</Title>
            <Paragraph>
              Отправили код подтверждения на номер
              <br />
              {formattedPhone}{' '}
              <ButtonAsLink
                onClick={stepsProps[CatalogRegistrationStep.CODE].onChangePhone}
                type="button"
              >
                Изменить
              </ButtonAsLink>
            </Paragraph>
          </FormHeader>
          <FormBody>
            <FormItems>
              <CodeField />
            </FormItems>
            <RequestCodeAgain context={context} requestCode={requestCode} />
            <ButtonAsLink
              onClick={errorContext.showMessengersPopup}
              type="button"
            >
              Не приходит код?
            </ButtonAsLink>
          </FormBody>
        </FormWrapWithoutButton>
        <ModalButtonGroup>
          <FormButton disabled={!code || code.length !== 4}>
            Продолжить
          </FormButton>
        </ModalButtonGroup>
      </FormMain>
    )
  }
)`
  ${ButtonAsLink} {
    width: fit-content;
  }
`

export const CodeStepForm = ({ onSubmit, psychologist }) => (
  <FinalForm
    decorators={[focusOnError]}
    mutators={{ clearSubmitError }}
    onSubmit={onSubmit}
    render={({ handleSubmit, form: { restart }, errors }) => (
      <Form
        errors={errors}
        handleSubmit={handleSubmit}
        psychologist={psychologist}
        restart={restart}
      />
    )}
    subscription={subscription}
  />
)
