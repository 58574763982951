import React from 'react'
import { SelectTimeForm } from './SelectTimeForm'
import { StyledModal } from './index.styles'

export const SelectTimeModal = ({ hide, handleSubmit }) => (
  <StyledModal hide={hide} title="Запись к психологу">
    <SelectTimeForm
      handleSubmit={() => {
        handleSubmit()
        hide()
      }}
    />
  </StyledModal>
)
