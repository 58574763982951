import { CatalogFieldNamesEnum } from '../../CatalogContextProvider/enums'
import { WizardGender } from '../../../atoms/mappers/wizard/enums/gender'
import { WizardHowFeeling } from '../../../atoms/mappers/wizard/enums/howFeeling'
import { WizardLanguage } from '../../../atoms/mappers/wizard/enums/language'
import { WizardPrices } from '../../../atoms/mappers/wizard/enums/prices'
import { WizardTherapyType } from '../../../atoms/mappers/wizard/enums/therapyType'
import { WizardYesNo } from '../../../atoms/mappers/wizard/enums/yesNo'
import { array, boolean, number, string } from 'yup'

const positiveInteger = number().positive().integer()
const language = string().oneOf(Object.values(WizardLanguage))
const therapyType = string().oneOf(Object.values(WizardTherapyType))
const howFeeling = string().oneOf(Object.values(WizardHowFeeling))
const gender = string().oneOf(Object.values(WizardGender))
const preference = string().oneOf(Object.values(WizardYesNo))
const wizardPrices = string().oneOf(Object.values(WizardPrices))

export const getWizardFieldsConfig = () => ({
  // HIDDEN IN IT-4692
  // [CatalogFieldNamesEnum.Language]: {
  //   schema: language.required(),
  //   step: 1
  // },
  [CatalogFieldNamesEnum.Client]: {
    schema: therapyType.required(),
    step: 1
  },
  [CatalogFieldNamesEnum.HowFeeling]: {
    schema: howFeeling,
    step: 2
  },
  [CatalogFieldNamesEnum.Name]: {
    schema: string().required(),
    step: 3
  },
  [CatalogFieldNamesEnum.Age]: {
    schema: positiveInteger.min(0).max(120).required(),
    step: 3
  },
  [CatalogFieldNamesEnum.WorkAreas]: {
    schema: array().of(string()).required(),
    step: 4
  },
  [CatalogFieldNamesEnum.AboutHelp]: {
    schema: string(),
    step: 5
  },
  [CatalogFieldNamesEnum.Gender]: {
    schema: gender,
    step: 6
  },
  [CatalogFieldNamesEnum.PsychologistAge]: {
    schema: array().of(positiveInteger.min(24).max(65)).length(2),
    step: 6
  },
  [CatalogFieldNamesEnum.Friendliness]: {
    schema: boolean().required(),
    step: 6
  },
  [CatalogFieldNamesEnum.Prices]: {
    schema: array().of(wizardPrices),
    step: 7
  },
  [CatalogFieldNamesEnum.Preference]: {
    schema: preference.required(),
    step: 8
  },
  [CatalogFieldNamesEnum.Approaches]: {
    schema: array().of(string()),
    step: 9
  }
})

export const getCatalogFieldsConfig = () => ({
  [CatalogFieldNamesEnum.Language]: {
    schema: language.required(),
    step: 1
  },
  [CatalogFieldNamesEnum.Client]: {
    schema: therapyType.required(),
    step: 2
  }
})
