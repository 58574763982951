import React, { useCallback } from 'react'
import { Field, useField } from 'react-final-form'
import { FormItemOnControl } from '../../molecules/FormItem'
import { Input } from '../../atoms/FormItems/Input'
import { notEmpty } from '../../atoms/FormItems/validators/notEmpty'

const useOnChangeAgeField = (onChange) =>
  useCallback(
    (event) => {
      if (!event.target) return onChange(event)

      const { value } = event.target
      const replaceValue = value.replace(/[^0-9]+/g, '')

      const notEmptyString = replaceValue !== ''

      if (notEmptyString && Number(replaceValue) > 120) {
        return onChange(120)
      }
      if (notEmptyString && Number(replaceValue) < 1) {
        return onChange(1)
      }

      return onChange(replaceValue)
    },
    [onChange]
  )

export const Age = ({ label, placeholder, name = 'age' }) => {
  const { input } = useField('age', { value: true })
  const onChange = useOnChangeAgeField(input.onChange)

  return (
    <Field name={name} validate={notEmpty('Укажите возраст')}>
      {({ input, meta }) => (
        <FormItemOnControl required label={label || 'Возраст'} meta={meta}>
          <Input
            placeholder={placeholder ?? 'Полных лет'}
            {...input}
            error={meta.error && meta.touched && !meta.active}
            id={name}
            inputMode="numeric"
            onChange={onChange}
          />
        </FormItemOnControl>
      )}
    </Field>
  )
}
