import React from 'react'
import styled from 'styled-components'
import {
  Button,
  Emojis,
  Illustrations,
  Popup,
  PopupBody,
  Text,
  size
} from '../../../youtalk-storybook/src/ui'
import { messengersUrl } from '../../../static/db.json'
import { selectDeviceSizeIsMobile } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ModalContent = styled(({ className, title }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  return (
    <div className={className}>
      <Illustrations.IllustrationMessage size={isMobile ? 'small' : 'medium'} />
      <TextContent>
        <Text.Large bold>{title}</Text.Large>
        <Text.Average>
          Напишите нашим специалистам
          <br /> в мессенджере
        </Text.Average>
      </TextContent>
      <Button.Grey
        AfterContent={<Emojis.EmojiTelegram />}
        href={messengersUrl.telegram}
        size={isMobile ? 'medium' : 'small'}
        type="link"
      >
        Telegram
      </Button.Grey>
      <Button.Grey
        AfterContent={<Emojis.EmojiWhatsapp />}
        href={messengersUrl.whatsapp}
        size={isMobile ? 'medium' : 'small'}
        type="link"
      >
        WhatsApp
      </Button.Grey>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;

  & ${Button.Grey} {
    width: 100%;
  }
`

const DEFAULT_TITLE = 'Возникли проблемы?'

export const MessengersPopup = styled(
  ({ className, title = DEFAULT_TITLE, hide }) => {
    const isMobile = useSelector(selectDeviceSizeIsMobile)
    return (
      <Popup
        className={className}
        hide={hide}
        isMobile={isMobile}
        showCloseButton={!isMobile}
      >
        <ModalContent title={title} />
      </Popup>
    )
  }
)`
  z-index: 14;
  ${PopupBody} {
    max-width: 425px;
  }

  @media (max-width: ${size.sm}) {
    height: var(--height);
    ${PopupBody} {
      max-width: 100%;
    }
  }
`
