import App from '../components/App'
import Header from '../components/Header'
import React, { createContext, useContext, useMemo } from 'react'
import { PsychologistCard } from '../organisms/PsychologistCard'
import { useLocation } from '@reach/router'

const PsychologistProfileContext = createContext()

export const PsychologistProfileProvider = ({ children, psychologist }) => (
  <PsychologistProfileContext.Provider
    value={{
      psychologist
    }}
  >
    {children}
  </PsychologistProfileContext.Provider>
)

export const usePsychologistProfileContext = () =>
  useContext(PsychologistProfileContext)

function Psychologist({ pageContext: { psychologist, allIds } }) {
  const location = useLocation()
  const [_, locationPsychologistId] = useMemo(
    () => location.pathname.split('/').filter((path) => path),
    [location]
  )

  const cardProps = useMemo(
    () => ({
      psychologist,
      allIds,
      locationPsychologistId
    }),
    []
  )

  return (
    <PsychologistProfileProvider psychologist={psychologist}>
      <App>
        <Header />
        <PsychologistCard {...cardProps} />
      </App>
    </PsychologistProfileProvider>
  )
}

export default Psychologist
