import React from 'react'
import { Text } from '../../../youtalk-storybook/src/ui'

export const InformationAbout = ({ psychologist }) => {
  const splittedAbout = psychologist.about
    ? psychologist.about.split('\n').filter((aboutStr) => aboutStr.length > 0)
    : ['В этом разделе психолог расскажет о себе']

  return splittedAbout.map((sentense, index) => (
    <div key={`about${index}`}>
      <>
        {index > 0 && <br />}
        <Text.Medium semiBold>{sentense}</Text.Medium>
      </>
    </div>
  ))
}
