import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '../../../youtalk-storybook/src/ui'
import { useFormState } from 'react-final-form'

const ButtonWithErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const FormButton = styled(
  ({ className, children, disabled = false }) => {
    const { errors, submitting } = useFormState()

    const defaultDisabledCond = useMemo(
      () => Object.keys(errors).length > 0,
      [errors]
    )

    return (
      <div className={className}>
        <ButtonWithErrorMessage>
          <Button.NewPrimary
            disabled={defaultDisabledCond || disabled}
            loading={submitting}
            type="submit"
            width="fullScreen"
          >
            {children}
          </Button.NewPrimary>
        </ButtonWithErrorMessage>
      </div>
    )
  }
)`
  ${Button.NewPrimary} {
    width: 100%;
  }

  display: block;
  width: 100%;
  gap: 24px;
`
