import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Button, Icons, Text } from '../../../youtalk-storybook/src/ui'
import { ReactComponent as CheckedSVG } from './img/checked.svg'
import { isBrowser } from 'framer-motion'
import { isMobile } from '../../../youtalk-storybook/src/ui/atoms/isMobile'
import { usePsyCardStorage } from './usePsyCardStorage'

const WorkArea = styled.div`
  display: flex;
  padding: 2px 16px;
  background-color: #f8f9f9; // add to colors
  border-radius: 16px;
  & ${Text.Average} {
    font-weight: 600;
  }
`

const FitWorkArea = styled(WorkArea)`
  display: flex;
  gap: 1px;
  background-color: #e9f2fd; // add to colors
  color: #2963a3; // add to colors
`

const DEFAULT_NUMBER_OF_WORK_AREAS_SHOW = 10
const DEFAULT_NUMBER_OF_WORK_AREAS_SHOW_MOBILE = 5

// eslint-disable-next-line no-unused-vars
const WorkAreasList = styled(({ workAreas, showAll, className }) => {
  if (!isBrowser) return null

  const [storedValue] = usePsyCardStorage()
  const fitWorkAreas = workAreas.filter((wa) =>
    storedValue?.workAreas?.some((wizardWorkArea) => wizardWorkArea === wa.id)
  )
  const restWorkAreas = workAreas.filter(
    (wa) => !fitWorkAreas?.some((fitWorkArea) => fitWorkArea.id === wa.id)
  )

  const numberOfWorkAreasToShow = isMobile
    ? DEFAULT_NUMBER_OF_WORK_AREAS_SHOW_MOBILE
    : DEFAULT_NUMBER_OF_WORK_AREAS_SHOW

  const restWorkAreasToShow = useMemo(() => {
    if (showAll) {
      return restWorkAreas
    }
    return restWorkAreas.slice(
      0,
      numberOfWorkAreasToShow - fitWorkAreas?.length
    )
  }, [showAll, fitWorkAreas, restWorkAreas, numberOfWorkAreasToShow])

  return (
    <div className={className}>
      {fitWorkAreas.map((wa) => (
        <FitWorkArea key={wa.id}>
          <CheckedSVG />
          <Text.Average bold>{wa.tag}</Text.Average>
        </FitWorkArea>
      ))}
      {restWorkAreasToShow.map((wa) => (
        <WorkArea key={wa.id}>
          <Text.Average bold>{wa.tag}</Text.Average>
        </WorkArea>
      ))}
    </div>
  )
})`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const WorkAreasShowOrHideButton = styled(Button.NoBordersBlack)`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  height: 20px;
  padding: 0;
`

export const ShowOrHideButton = ({ showAll, setShowAll, scrollTo }) =>
  showAll ? (
    <WorkAreasShowOrHideButton
      AfterContent={<Icons.IconArrowUp />}
      onClick={() => {
        setShowAll(false)
        scrollTo()
      }}
    >
      Скрыть
    </WorkAreasShowOrHideButton>
  ) : (
    <WorkAreasShowOrHideButton
      AfterContent={<Icons.IconArrowDown />}
      onClick={() => setShowAll(true)}
    >
      Посмотреть все
    </WorkAreasShowOrHideButton>
  )

export const WorkAreasBlock = styled(({ psychologist, className }) => {
  const showBlock =
    psychologist.work_areas && psychologist.work_areas.length > 0
  if (!showBlock) return null

  const [showAll, setShowAll] = useState(false)
  const showButtons =
    psychologist.work_areas.length > DEFAULT_NUMBER_OF_WORK_AREAS_SHOW

  return (
    <div className={className}>
      <Text.Large bold>Работает с запросами</Text.Large>
      <WorkAreasList showAll={showAll} workAreas={psychologist.work_areas} />
      {showButtons && (
        <ShowOrHideButton setShowAll={setShowAll} showAll={showAll} />
      )}
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`
