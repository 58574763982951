import { Language } from '../../../atoms/mappers/gqlEnums/language'
import { RegistrationSource } from '../../../atoms/mappers/gqlEnums/registrationSourse'
import { SourceID } from '../../../atoms/mappers/wizard/enums/sourceId'
import { number, object, string } from 'yup'

const positiveInteger = number().positive().integer()
const registrationSource = string().oneOf(Object.values(RegistrationSource))
const sourceID = string().oneOf(Object.values(SourceID))
const language = string().oneOf(Object.values(Language))

const userRegistrationPreferences = object({
  aboutHelp: string(),
  language: language.required()
})

const analyticsData = object({
  sourceId: sourceID.required()
})

export const sendCatalogRegistrationRequestInputSchema = object({
  phone: positiveInteger.required(),
  name: string().required(),
  age: positiveInteger.required(),
  userRegistrationPreferences: userRegistrationPreferences.required(),
  registrationSource: registrationSource.required(),
  email: string(),
  timezone: string(),
  promocodeId: string(),
  analyticsData: analyticsData.required(),
  attempt: number()
})
