import styled, { css } from 'styled-components'
import { Button, color, size } from '../../../../../youtalk-storybook/src/ui'
import { Loader } from '../../../../../youtalk-storybook/src/ui/atoms/Loader'

export const StyledForm = styled.form`
  margin-bottom: ${(props) => (props.isModal ? '0px' : '48px')};
  width: 384px;
  position: sticky;
  top: 15px;
  height: fit-content;

  @media (max-width: ${size.lg}) {
    width: 387px;
  }

  @media (max-width: ${size.md}) {
    width: 306px;
  }
  @media (max-width: ${size.sm}) {
    width: 100%;
    bottom: 0px;
    top: unset;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  padding: ${(props) => (props.isModal ? '0px' : '24px')};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: ${(props) => (props.isModal ? 'none' : '1px solid #e6e6e6')};
  background: ${color.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  transition: all 0.33s ease;
  min-height: 220px;
  transition: all 0.33s ease-in;

  ${({ loading }) =>
    loading &&
    css`
      align-items: center;

      ${Loader} {
        display: block;
        position: relative;
      }
    `}

  & abbr[title] {
    text-decoration: none;
  }
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${Button.NewOutline}, ${Button.NewPrimary} {
    width: 100%;
  }
`

export const RecordButton = styled(Button.NewPrimary)`
  width: 100%;
`

export const SelectTimeButton = styled(Button.NewPrimary)`
  width: fit-content;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`
