import { gql, useMutation } from '@apollo/client'

const SEND_REGISTRATION_REQUEST = gql`
  mutation sendRegistrationRequest($input: SendRegistrationRequestInput!) {
    sendRegistrationRequest(input: $input) {
      ... on AuthErrorResponse {
        code
        message
      }
      ... on SendRegistrationRequestResponseSuccess {
        token
        datetime
        bitrixLeadId
      }
    }
  }
`

const CONFIRM_REGISTRATION = gql`
  mutation confirmRegistration($input: ConfirmRegistrationInput!) {
    confirmRegistration(input: $input) {
      ... on AuthErrorResponse {
        code
        message
      }
      ... on ConfirmRegistrationResponseSuccess {
        jwt
        id
        hadAnyRelationships
        newPsychologistId
      }
    }
  }
`

export const useSendRegistrationRequestMutation = (input) =>
  useMutation(SEND_REGISTRATION_REQUEST, input)

export const useConfirmRegistrationMutation = (input) =>
  useMutation(CONFIRM_REGISTRATION, input)
