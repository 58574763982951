import React, { useEffect, useMemo } from 'react'
import { AuthModal } from '../index.styles'
import {
  CatalogRegistrationModalContextProvider,
  useCatalogRegistrationModalContext
} from './hooks/useCatalogRegistrationModalContext'
import { CatalogRegistrationStep } from './vars'
import { CodeStep } from './CodeStep'
import { Event as GAEvent } from '../../../components/GA'
import { IdentityStep } from './IdentityStep'
import { MeetStep } from './MeetStep'
import { isBrowser } from '../../../atoms/root'
import { selectDeviceSizeIsMobile } from '../../../state/reducers/deviceSlice'
import { useCodeStepSubmit } from './hooks/useCodeStepSubmit'
import { useIdentityStepSubmit } from './hooks/useIdentityStepSubmit'
import { useMeetStepSubmit } from './hooks/useMeetStepSubmit'
import { useSelector } from 'react-redux'

// eslint-disable-next-line max-lines-per-function
export const CatalogRegistrationModal = ({ psychologist }) => {
  const { stepsProps, modal, ...context } = useCatalogRegistrationModalContext()
  const onMeetSubmit = useMeetStepSubmit()
  const onIdentitySubmit = useIdentityStepSubmit(psychologist)
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  const onCodeSubmit = useCodeStepSubmit()

  useEffect(() => {
    if (isBrowser) {
      GAEvent.visitRegistration('popup')
    }
  }, [])

  const modalProps = useMemo(() => {
    const currentStep = context.formData.step
    const stepWithBackButtonProps = {
      backButtonText: `${
        currentStep === CatalogRegistrationStep.IDENTITY ? 1 : 2
      } из 2 шагов`,
      BackButtonIcon: null,
      withBackButtonHeader: true
    }
    return {
      ...(context.formData.step !== CatalogRegistrationStep.MEET
        ? stepWithBackButtonProps
        : {}),
      ...(context.formData.loading
        ? {
            modalHeight: 425,
            loading: true
          }
        : {}),
      onBackButtonClick: stepsProps[currentStep].onBack
    }
  }, [context.formData, isMobile])

  return (
    <AuthModal hide={modal.hide} {...modalProps}>
      {context.formData.step === CatalogRegistrationStep.MEET && (
        <MeetStep onMeetSubmit={onMeetSubmit} />
      )}
      {context.formData.step === CatalogRegistrationStep.IDENTITY && (
        <IdentityStep onIdentitySubmit={onIdentitySubmit} />
      )}
      {context.formData.step === CatalogRegistrationStep.CODE && (
        <CodeStep onCodeSubmit={onCodeSubmit} psychologist={psychologist} />
      )}
    </AuthModal>
  )
}

export const CatalogRegistrationModalWithContext = ({
  children,
  catalogFiltersData,
  disabled
}) =>
  disabled ? (
    children
  ) : (
    <CatalogRegistrationModalContextProvider
      catalogFiltersData={catalogFiltersData}
    >
      {children}
    </CatalogRegistrationModalContextProvider>
  )
