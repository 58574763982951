import dayjs from 'dayjs'
import { useMemo } from 'react'

export const DISABLED_PROPS_MODE = {
  WEEK: 'week',
  MONTH: 'month'
}

const disabledPrevMap = {
  [DISABLED_PROPS_MODE.WEEK]: 'disabledPrevWeekCondition',
  [DISABLED_PROPS_MODE.MONTH]: 'disabledPrevMonthCondition'
}

export const useDisabledProps = ({ mode, disabledDates }) =>
  useMemo(
    () => ({
      [disabledPrevMap[mode]]: (date) => {
        const breakpoint = dayjs().startOf(mode)
        if (breakpoint.isSame(date.startOf(mode))) {
          return true
        }
        return date.startOf(mode).isBefore(breakpoint)
      },
      disabledDates
    }),
    [disabledDates, mode]
  )
