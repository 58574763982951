import React from 'react'
import styled from 'styled-components'
import { QuestionsList } from '../../molecules/QuestionsList'
import { Text } from '../../atoms/Text'

const StyledTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 24px;
`

export const PsychologistFAQBlock = styled(({ className }) => (
  <div className={className}>
    <StyledTitle>Самые частые вопросы</StyledTitle>
    <ul>
      <QuestionsList className={className} page="Psychologist" />
    </ul>
  </div>
))`
  display: flex;
  flex-direction: column;

  .questions-list-item__tgl {
    padding-left: 0;
  }

  > ul {
    margin: 0;
    padding: 0;
  }

  ul .questions-list-item:first-child {
    box-shadow: none;
  }

  .questions-list-item {
    padding: 18px 0;
  }

  .questions-list__item:last-child {
    margin-bottom: 0;
  }

  .questions-list-item__ans {
    padding-top: 8px;
  }

  .questions-list-item__ans ul li {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .questions-list-item__ans p {
    margin-top: 4px;
    margin-bottom: 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  & > ul > li > button {
    background-color: transparent;

    & > h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }
  }
`
