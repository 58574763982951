import qs from 'qs'
import { PT_SOURCE_QUERY_PARAM } from '../../CatalogContextProvider/hooks/useQuerySave'
import { useLocation } from '@reach/router'

export const useGetParentRoute = () => {
  const { search } = useLocation()
  const searchObj = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  return searchObj[PT_SOURCE_QUERY_PARAM] ?? 'direct'
}
