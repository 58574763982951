export const getCurrentWeek = (currentDate) => {
  const firstDayOfWeek = currentDate.startOf('week')
  const daysOfWeek = []
  for (let i = 0; i < 7; i++) {
    const day = firstDayOfWeek.add(i, 'day')
    daysOfWeek.push(day.startOf('day'))
  }

  return daysOfWeek
}

export const getCurrentMonth = (currentDate) => {
  const startOfMonth = currentDate.startOf('month')
  const deadline = startOfMonth.endOf('month')

  const daysInMonth = []

  let currentDay = startOfMonth

  while (currentDay.isBefore(deadline) || currentDay.isSame(deadline)) {
    daysInMonth.push(currentDay)
    currentDay = currentDay.add(1, 'day')
  }

  return daysInMonth
}
