import qs from 'qs'
import { PT_SOURCE_QUERY_PARAM } from '../CatalogContextProvider/hooks/useQuerySave'
import { useLocalStorage } from '../../../youtalk-storybook/src/ui'
import { useLocation } from '@reach/router'

export const useFilterStorage = () => {
  const { search } = useLocation()
  const searchObj = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  return useLocalStorage(searchObj[PT_SOURCE_QUERY_PARAM], {})
}
