import React, { createContext, useEffect, useState } from 'react'
import { INITIAL_CATALOG_FILTERS } from './vars'
import { mergeDeepRight } from 'rambda'
import { string } from 'yup'
import { useLocalStorage } from '../../../youtalk-storybook/src/ui'
import { useQuerySave } from './hooks/useQuerySave'

export const CatalogContext = createContext()

export const CatalogKeysEnum = {
  Loading: 'loading',
  HasMore: 'hasMore',
  Enabled: 'enabled',
  State: 'state',
  Pagination: 'pagination',
  FullyMatch: 'fullyMatch',
  QueryFilters: 'queryFilters',
  Page: 'page',
  IsFirstRequest: 'isFirstRequest',
  IsUrgent: 'isUrgent'
}

export const INITIAL_CATALOG_PAGINATION = {
  skip: 0,
  limit: 6
}

export const initialValues = {
  state: [],
  pagination: INITIAL_CATALOG_PAGINATION,
  loading: { initial: true, pagination: false },
  hasMore: false,
  enabled: false,
  queryFilters: {},
  isFirstRequest: true,
  IsUrgent: false
}

export const CatalogContextSourceEnum = {
  Wizard: 'wizard',
  Catalog: 'catalog',
  Main: 'main'
}

const registeredFields = string().oneOf(Object.values(CatalogKeysEnum))

export const CatalogContextProvider = ({ children, ...initialState }) => {
  const initial = mergeDeepRight(initialValues, initialState)
  const [state, setState] = useState(initial)

  const change = (args) => {
    setState({
      ...state,
      ...args
    })
  }

  useEffect(() => {
    Object.keys(state ?? {}).forEach((fieldName) => {
      try {
        registeredFields.validateSync(fieldName)
      } catch (error) {
        console.error(fieldName, error)
      }
    })
  }, [])

  const key = initial.page.source
  const storage = useLocalStorage(key, INITIAL_CATALOG_FILTERS)

  useQuerySave({
    enabled: key === CatalogContextSourceEnum.Catalog,
    cache: storage
  })

  return (
    <CatalogContext.Provider
      value={{
        state,
        storage,
        change
      }}
    >
      {children}
    </CatalogContext.Provider>
  )
}
