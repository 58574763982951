import { Language } from '../../../../atoms/mappers/gqlEnums/language'
import { SessionType } from '../../../../atoms/mappers/gqlEnums/sessionType'
import { WizardLanguage } from '../../../../atoms/mappers/wizard/enums/language'
import { WizardSessionFormat } from '../../../../atoms/mappers/wizard/enums/sessionFormat'
import { WizardTherapyType } from '../../../../atoms/mappers/wizard/enums/therapyType'
import { selectorValueToProps } from '../WebView/SelectTimeForm'
import { useCallback } from 'react'
import { useLoggedUsername } from '../../../../components/Header/useLoggedUsername'
import { useOpenStartRegistrationModal } from '../../useOpenStartRegistrationModal'
import { usePsyCardStorage } from '../../usePsyCardStorage'

const getSessionDateTime = ({ date, time }) => {
  const isRange = Array.isArray(date)
  if (isRange) {
    return date[0].startOf('day').toDate()
  }
  const [hours, minutes] = time.split(':')

  return date
    .set('hour', Number(hours))
    .set('minute', Number(minutes))
    .startOf('minute')
    .toDate()
}

const gqlSessionFormatToWizard = {
  [SessionType.Individual]: WizardSessionFormat.Video,
  [SessionType.Family]: WizardSessionFormat.Video
}

const getClientType = (type, storedValue) => {
  if (type === SessionType.Family) {
    if (storedValue.client === WizardTherapyType.FamilyChild) {
      return WizardTherapyType.FamilyChild
    }
    return WizardTherapyType.FamilyPartner
  }
  return WizardTherapyType.Individual
}

const getSessionInfoFromSelector = (selectorValues, storedValue) => {
  const [type, language] = selectorValueToProps(selectorValues)

  return {
    client: getClientType(type, storedValue),
    language:
      language === Language.Russian
        ? WizardLanguage.Russian
        : WizardLanguage.English,
    sessionFormat: gqlSessionFormatToWizard[type]
  }
}

export const useFormSubmit = () => {
  const startRegistration = useOpenStartRegistrationModal()
  const [loggedUserName] = useLoggedUsername()
  const [storedValue, setStoredValue] = usePsyCardStorage()

  return useCallback(
    (values) => {
      setStoredValue({
        ...storedValue,
        sessionDateTime: getSessionDateTime({
          date: values.date,
          time: values.time
        }),
        ...getSessionInfoFromSelector(
          values.sessionTypeAndLanguage,
          storedValue
        )
      })
      if (!loggedUserName) {
        startRegistration()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedValue]
  )
}
