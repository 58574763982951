import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { ButtonAsLink, Countdown } from '../../../youtalk-storybook/src/ui'
import { LOGIN_RESEND_TIME } from './vars'
import { Paragraph } from './index.styles'

const isDeadline = (datetime) => {
  const now = dayjs()
  return now.isAfter(dayjs(datetime).add(LOGIN_RESEND_TIME, 'seconds'))
}

export const RequestCodeAgain = styled(
  ({ className, context, requestCode }) => {
    const onComplete = useCallback(() => {
      context.updateContext({ canBeRequestAgain: true })
    }, [])

    const time = useMemo(() => {
      if (!dayjs(context.formData.datetime).isValid()) {
        return dayjs().toISOString()
      }

      return dayjs(context.formData.datetime)
        .add(LOGIN_RESEND_TIME, 'seconds')
        .toISOString()
    }, [context.formData.datetime, context.formData.canBeRequestAgain])

    const onTick = useCallback(() => {
      if (isDeadline(context.formData.datetime)) {
        onComplete()
      }
    }, [context.formData.datetime])

    if (context.formData.canBeRequestAgain) {
      return (
        <div className={className}>
          <ButtonAsLink onClick={requestCode} type="button">
            Отправить код повторно
          </ButtonAsLink>
        </div>
      )
    }

    return (
      <Paragraph className={className}>
        Запросить код повторно можно через
        <br />
        <Countdown onComplete={onComplete} onTick={onTick} time={time} /> секунд
      </Paragraph>
    )
  }
)`
  width: fit-content;
  text-align: left;
  height: 40px;
`
