import React from 'react'
import { CodeStepForm } from './form'
import { RegistrationRoute } from '../../../../components/amplitude/auth/vars'
import { useTrackCodeEnterEffect } from '../../../../components/amplitude/auth/trackCodeEnter'

export const CodeStep = ({ onCodeSubmit, psychologist }) => {
  useTrackCodeEnterEffect({ route: RegistrationRoute.Wizard })

  return <CodeStepForm onSubmit={onCodeSubmit} psychologist={psychologist} />
}
