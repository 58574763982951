import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  AgreementText,
  FormHeader,
  FormItems,
  FormMain,
  FormWrapWithoutButton,
  ModalButtonGroup,
  Paragraph,
  SmsInfoBlock,
  SmsInfoSvg,
  SmsInfoText,
  Title
} from '../../index.styles'
import { Form as FinalForm } from 'react-final-form'
import { FormButton } from '../../FormButton'
import { InternationalTelephone } from '../../../Form/Input'
import { documents } from '../../../../../static/db.json'
import {
  submit,
  useInitialFormValuesAfterDOMLoad,
  useInitialFormValuesFromParams
} from '../../../../atoms/useFormValues'
import { subscription } from '../vars'
import { useCatalogRegistrationModalContext } from '../hooks/useCatalogRegistrationModalContext'

const Form = styled(({ className, handleSubmit, restart, errors, values }) => {
  useInitialFormValuesFromParams([{ fieldName: 'promo' }])
  useInitialFormValuesAfterDOMLoad([{ fieldName: 'tel', value: '+7' }])
  const { updateContext } = useCatalogRegistrationModalContext()
  const onChangeCountry = useCallback(
    (country) => updateContext({ country }),
    []
  )

  return (
    <FormMain
      noValidate
      className={className}
      onSubmit={submit({
        handleSubmit,
        restart,
        errors,
        initialValues: values
      })}
    >
      <FormWrapWithoutButton>
        <FormHeader>
          <Title bold>Регистрация</Title>
          <Paragraph>
            Создайте аккаунт, чтобы начать заниматься с психологом
          </Paragraph>
        </FormHeader>
        <FormItems>
          <InternationalTelephone onChangeCountry={onChangeCountry} />
        </FormItems>
      </FormWrapWithoutButton>
      <ModalButtonGroup>
        <FormButton>Зарегистрироваться</FormButton>
      </ModalButtonGroup>
      <SmsInfoBlock>
        <SmsInfoSvg />
        <SmsInfoText>
          Никаких звонков, только SMS о статусе сессий. А ещё на этот номер
          напишет выбранный психолог в мессенджер
        </SmsInfoText>
      </SmsInfoBlock>
      <AgreementText>
        {'Отправляя заявку, я соглашаюсь на '}
        <a href={documents.urlPolicy} rel="noreferrer" target="_blank">
          обработку персональных данных
        </a>
        {' и с '}
        <a href={documents.urlAgreement} rel="noreferrer" target="_blank">
          условиями лицензионного соглашения
        </a>
      </AgreementText>
    </FormMain>
  )
})``

export const IdentityStepForm = ({ onSubmit }) => {
  const { formData } = useCatalogRegistrationModalContext()

  return (
    <FinalForm
      initialValues={{
        agreement: true,
        tel: formData.tel
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { restart }, errors, values }) => (
        <Form
          errors={errors}
          handleSubmit={handleSubmit}
          restart={restart}
          values={values}
        />
      )}
      subscription={subscription}
      validate={() => {}}
    />
  )
}
