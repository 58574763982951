import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useForm } from 'react-final-form'

export const useInitialValue = (nearestSlot) => {
  const [init, setInit] = useState(false)
  const { change, batch } = useForm()
  useEffect(() => {
    const { datetime } = nearestSlot ?? {}
    if (datetime && !init) {
      setInit(true)
      batch(() => {
        change('date', dayjs(datetime))
        change('time', dayjs(datetime).format('HH:mm'))
      })
    }
  }, [nearestSlot])
}
