import { SessionType } from '../../../../atoms/mappers/gqlEnums/sessionType'

const Language = {
  RUSSIAN: 'russian',
  ENGLISH: 'english'
}
const TherapyType = {
  INDIVIDUAL: 'individual',
  FAMILY_CHILD: 'family_child',
  FAMILY_PARTNER: 'family_partner'
}

export const PRICES = {
  [Language.RUSSIAN]: {
    [TherapyType.INDIVIDUAL]: 250_000,
    [TherapyType.FAMILY_CHILD]: 350_000,
    [TherapyType.FAMILY_PARTNER]: 350_000
  },
  [Language.ENGLISH]: {
    [TherapyType.INDIVIDUAL]: 350_000,
    [TherapyType.FAMILY_CHILD]: 500_000,
    [TherapyType.FAMILY_PARTNER]: 500_000
  }
}

export const OptionsNames = {
  [SessionType.Individual]: 'Видео/Аудио',
  [SessionType.Family]: 'Видео/Аудио',
  [SessionType.SyncChat]: 'Переписка',
  [SessionType.AsyncChat]: 'Переписка'
}

export const OptionsCaptions = {
  [SessionType.Individual]: 'Индивидуальная • 50 минут',
  [SessionType.Family]: 'Семейная  • 50 минут',
  [SessionType.SyncChat]: 'Синхронная переписка • 50 минут',
  [SessionType.AsyncChat]: 'Асинхронная переписка • 1 неделя'
}
