import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  Button,
  Illustrations,
  Popup,
  PopupBody,
  Text
} from '../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../components/GA'
import { ModalContent } from '../components/Modal'
import { lkURL, size } from '../constants'
import { selectDeviceSizeIsMobile } from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const Content = styled.div``
const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Icon = styled(Illustrations.IllustrationUserCard)``

export const IdentityNotAvailablePopup = styled(
  ({ className, hide, onOpen }) => {
    const isMobile = useSelector(selectDeviceSizeIsMobile)
    useEffect(() => {
      typeof onOpen === 'function' && onOpen()
    }, [])

    useEffect(() => {
      GAEvent.showAgeWarningInForm()
    }, [])

    return (
      <Popup
        className={className}
        hide={hide}
        isMobile={isMobile}
        showCloseButton={!isMobile}
      >
        <Content>
          <Icon size={isMobile ? 'small' : 'medium'} />
          <TextContent>
            <Text.Large bold>
              Аккаунт с таким телефоном уже существует
            </Text.Large>
            <Text.Average semiBold>
              Войдите по номеру телефона. Если хотите сменить психолога сделайте
              это из личного кабинета.
            </Text.Average>
          </TextContent>
          <Button.NewPrimary
            href={lkURL}
            size="medium"
            target="_blank"
            type="link"
          >
            Войти по номеру телефона
          </Button.NewPrimary>
        </Content>
      </Popup>
    )
  }
)`
  && {
    max-height: var(--height);
    height: var(--height);

    ${PopupBody} {
      max-width: 504px;
    }

    ${Button.NewPrimary} {
      width: 100%;
    }

    ${Content} {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 32px;
    }

    @media (max-width: ${size.sm}) {
      ${Content} {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 16px;
      }

      ${Text.Large} {
        font-size: 18px;
        line-height: 26px;
      }

      ${ModalContent} {
        padding-top: 72px;
      }
    }
  }
`
